import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import { supabase } from "../Components/supabaseClient";
import useFetchClubs from "../Components/Hooks/useFetchClubs";
import useSendMessage from "../Components/Hooks/useSendMessage";
import { useCheckExistingConversation } from "../Components/Hooks/CheckExistingConversation";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import "../Components/Styles/ChatScreen.css";
import { Colors } from "../Components/Theme/Colors";

const ChatScreen = () => {
  const [newMessages, setNewMessages] = useState([]);
  const subscriptionRef = useRef(null);
  const clubs = useFetchClubs();
  const [searchConversations, setSearchConversations] = useState("");
  const [searchAllClubs, setSearchAllClubs] = useState("");

  const {
    messages,
    clickedClubName,
    signedInUserClubName,
    existingConversationId,
    checkExistingConversation,
    listOfConversations,
  } = useCheckExistingConversation();

  const { message, setMessage, sendMessage } = useSendMessage(
    existingConversationId,
    signedInUserClubName,
    clickedClubName
  );
  useEffect(() => {
    readMessages();
  }, [clickedClubName]);

  useEffect(() => {
    if (!existingConversationId) return;
    
    if (subscriptionRef.current) {
      subscriptionRef.current.unsubscribe();
      subscriptionRef.current = null;
    } 
  
    subscriptionRef.current = supabase
      .channel(`conversation-${existingConversationId}`)
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "messages",
          filter: `conversation_id=eq.${existingConversationId}`,
        },
        async (payload) => {
          const newMessage = payload.new;
  
          // Fetch club name for sender
          const { data: senderClub, error } = await supabase
            .from("clubs") // Ensure correct table name
            .select("club_name")
            .eq("club_uuid", newMessage.sender_id)
            .single();
  
          if (!error && senderClub) {
            newMessage.sender_name = senderClub.club_name;
          } else {
            newMessage.sender_name = "Unknown";
          }
          readMessages();
          setNewMessages((prevNewMessages) => [...prevNewMessages, newMessage]);
        }
      )
      .subscribe();
  
    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
        subscriptionRef.current = null;
      }
    };
  }, [existingConversationId]);
  

  const readMessages = async () => {
    try {
      const { data, error } = await supabase
        .from("messages")
        .select("id, read")
        .eq("conversation_id", existingConversationId)
        .neq("sender_id", signedInUserClubName);
  
      if (error) {
        console.error("Error fetching unread messages:", error);
        return;
      }
  
      if (data.length > 0) {
        await supabase
          .from("messages")
          .update({ read: true })
          .eq("conversation_id", existingConversationId)
          .neq("sender_id", signedInUserClubName);
      }
    } catch (error) {
      console.error("Error reading messages:", error);
    }
  };
  
  
  const deleteChat = async (existingConversationId) => {
    try {
      const { error } = await supabase
        .from("conversations")
        .delete()
        .eq("conversation_id", existingConversationId);
      if (error) throw error;
      checkExistingConversation("");
    } catch (error) {
      console.error("Error deleting conversation:", error.message);
    }
  };

  const onMessageClick = () => {
    if (message.trim() === "") return;
    sendMessage();
    setMessage("");
  };

  // Filter conversations based on search query
  const filteredConversations = listOfConversations.filter((conversation) =>
    (conversation.participant1_id === signedInUserClubName
      ? conversation.participant2_id
      : conversation.participant1_id
    )
      .toLowerCase()
      .includes(searchConversations.toLowerCase())
  );

  // Find clubs without an existing conversation
  const conversationClubNames = new Set(
    listOfConversations.map((conversation) =>
      conversation.participant1_id === signedInUserClubName
        ? conversation.participant2_id
        : conversation.participant1_id
    )
  );

  const clubsWithoutConversations = clubs.filter(
    (club) => !conversationClubNames.has(club.club_name)
  );

  // Filter all clubs based on search query
  const filteredClubs = clubsWithoutConversations.filter((club) =>
    club.club_name.toLowerCase().includes(searchAllClubs.toLowerCase())
  );

  return (
    <>
      <Header />
      <div className="chat-container">
        <aside className="sidebar">
          <h3 className="chat-container-header"> Recent Chats</h3>
          <input
            type="text"
            placeholder="Search conversations..."
            value={searchConversations}
            onChange={(e) => setSearchConversations(e.target.value)}
            className="search-input"
          />
          <div className="chat-list">
            {filteredConversations.map((conversation) => (
              <div
                key={conversation.conversation_id}
                className={`chat-item ${
                  conversation.conversation_id === existingConversationId ? "active" : ""
                }`}
                onClick={() =>
                  checkExistingConversation(
                    conversation.participant1_id === signedInUserClubName
                      ? conversation.participant2_id
                      : conversation.participant1_id
                  )
                }
              >
                <span>
                  {conversation.participant1_id === signedInUserClubName
                    ? conversation.participant2_id
                    : conversation.participant1_id}
                </span>
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteChat(conversation.conversation_id);
                  }}
                  style={{ cursor: "pointer", marginLeft: "8px" }}
                >
                  <DeleteIcon fontSize="small" />
                </span>
              </div>
            ))}
          </div>

          <h3 className="chat-container-header">All Clubs</h3>
          <input
            type="text"
            placeholder="Search all clubs..."
            value={searchAllClubs}
            onChange={(e) => setSearchAllClubs(e.target.value)}
            className="search-input"
          />
          <div className="club-list">
            {filteredClubs.map((club) => (
              <div
                key={club.club_uuid}
                className="club-item"
                onClick={() => checkExistingConversation(club.club_name)}
              >
                {club.club_name}
              </div>
            ))}
          </div>
        </aside>

        <section className="chat-main">
          <h2>{clickedClubName || "Select a chat"}</h2>
          <div className="chat-messages">
            {[...messages, ...newMessages].map((msg, index) => (
              <div
                key={index}
                className={`chat-message ${
                  msg.sender_id === signedInUserClubName ? "sent" : "received"
                }`}
              >
                <p>{msg.message_content}</p>
                <div className="message-meta">
                  <span className="sender-id">{msg.sender_name || msg.sender_id}</span> 
                  {msg.sender_id === signedInUserClubName && (
                    <CheckIcon
                      className="read-icon"
                      style={{ color: msg.read ? Colors.pink : Colors.black }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="chat-input-container">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && onMessageClick()}
              placeholder="Type a message..."
              className="chat-input"
            />
            <button onClick={onMessageClick} className="chat-send-btn">
              Send
            </button>
          </div>
        </section>
      </div>
    </>
  );
};

export default ChatScreen;
