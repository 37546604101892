import React, { useState } from "react";
import { supabase } from "../Components/supabaseClient";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Link,
  Card,
  CardContent,
} from "@mui/material";
import Header from './Header';
import { Colors } from "../Components/Theme/Colors";

const Login = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const loginUser = async () => {
    const { error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });
    if (error) {
      alert("Error logging in: " + error.message);
    } else {
      window.location.href = "/dashboard";
    }
  };

  return (
    <>
      <Header />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        style={{
          background: "linear-gradient(135deg, #19262d, #1e3a8a)", // Matching Sign Up background
        }}
      >
        <Container maxWidth="xs">
          <Card
            style={{
              padding: "2rem",
              boxShadow: "0px 4px 12px rgba(0,0,0,0.15)",
              backgroundColor: Colors.teal50, // Matching Sign Up card color
              borderRadius: "12px",
            }}
          >
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{ fontWeight: "bold", color: Colors.pinkDark }} // Matching Sign Up heading
                >
                  Login
                </Typography>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    style: { borderColor: Colors.pinkDark, borderRadius: "8px" },
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    style: { borderColor: "#D1D1D1", borderRadius: "8px" },
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    marginTop: "1rem",
                    borderRadius: "25px",
                    padding: "0.75rem",
                    fontWeight: "bold",
                    backgroundColor: "#6558f5", // FixtureFix brand color
                    color: "#FFFFFF",
                    boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
                    transition: "0.3s",
                  }}
                  onMouseOver={(e) => (e.target.style.backgroundColor = "#5648e0")}
                  onMouseOut={(e) => (e.target.style.backgroundColor = "#6558f5")}
                  onClick={loginUser}
                >
                  Login
                </Button>
                <Typography
                  variant="body2"
                  style={{ marginTop: "1rem", color: "#FFFFFF" }}
                >
                  Don't have an account?{" "}
                  <Link
                    href="/signup"
                    underline="hover"
                    style={{ color: Colors.pink, fontWeight: "bold" }}
                  >
                    Sign Up
                  </Link>
                </Typography>
              </Box>
            </CardContent>
          </Card>
          <Typography
            variant="body2"
            style={{
              marginTop: "2rem",
              textAlign: "center",
              color: "#FFFFFF",
            }}
          >
            © 2025 FixtureFix. All Rights Reserved.
          </Typography>
        </Container>
      </Box>
    </>
  );
  
};

export default Login;
