import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../Components/Images/logo.png";
import { Colors } from "../Components/Theme/Colors";

const menuItems = [
  { label: "Login", path: "/login" },
  { label: "Sign Up", path: "/signup" },
  { label: "About Us", path: "/aboutUs" },
  { label: "Chat", path: "/chatscreen" },
  { label: "All Clubs", path: "/clubList" },
  { label: "Map Of All Clubs", path: "/AllClubsOnMap" },
  { label: "List Of Games", path: "/listofgames" },
  { label: "Subscription", path: "/subscriptionscreen" },
  { label: "Contact Us", path: "/contactus" },
  { label: "Privacy Policy", path: "/privacy" },
  { label: "Terms And Conditions", path: "/tandc" },
  { label: "Logout", path: "/logout" },
];

const HeaderComponent = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleNavigation = (path) => {
    window.location.href = path;
    setDrawerOpen(false); // Close sidebar after selection
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: Colors.background,
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
          zIndex: 1100,
        }}
      >
        <Toolbar>
          {/* Sidebar Toggle Button */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ marginRight: "16px" }}
          >
            <MenuIcon />
          </IconButton>

          {/* App Title */}
          <Typography
            variant="h4"
            sx={{
              flexGrow: 1,
              textAlign: "center",
              fontWeight: "bold",
              color: Colors.white,
              cursor: "pointer",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            }}
            onClick={() => (window.location.href = "./dashboard")}
          >
            FixtureFix
          </Typography>

          {/* App Logo */}
          <img
            src={logo}
            alt="logo"
            height={75}
            onClick={() => (window.location.href = "/")}
            style={{
              cursor: "pointer",
              borderRadius: "50%",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          />
        </Toolbar>
      </AppBar>

      {/* Sidebar Drawer */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <div
          role="presentation"
          style={{ width: 250, padding: "10px" }}
        >
          {/* Sidebar Header with Close Button */}
          <IconButton
            onClick={toggleDrawer(false)}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <CloseIcon />
          </IconButton>

          {/* Sidebar Menu Items */}
          <List>
            {menuItems.map((item, index) => (
              <ListItem button key={index} onClick={() => handleNavigation(item.path)}>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>

          <Divider />
        </div>
      </Drawer>
    </>
  );
};

export default HeaderComponent;
