import React, { useEffect, useState } from "react";
import { Container, Typography, Button, Box, Paper, Grid } from "@mui/material";
import { supabase } from "../Components/supabaseClient";
import Header from './Header';

const SubscriptionScreen = () => {
  const [premiumMember, setPremiumMember] = useState(null);

  useEffect(() => {
    const fetchPremiumStatus = async () => {
      const { data, error } = await supabase
        .from("userProfile")
        .select("premium_member")
        .eq("club_name", "Jasons Cricket Club");

      if (error) {
        console.error("Error fetching premium status:", error);
        return;
      }
      setPremiumMember(data.length > 0 ? data[0].premium_member : false);
    };

    fetchPremiumStatus();
  }, []);

  return (
    <>
    <Header />
    <Box sx={styles.container}>
      
      <Container>
        <Typography variant="h3" sx={styles.title}>Pricing</Typography>
        <Typography variant="body1" sx={styles.subtitle}>
          Choose the plan that works for you
        </Typography>

        <Grid container spacing={4} justifyContent="center" sx={styles.grid}>
          {/* Free Plan */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper sx={styles.card}>
              <Typography variant="h5" sx={styles.planTitle}>Hobby</Typography>
              <Typography variant="h3" sx={styles.price}>Free</Typography>
              <Typography sx={styles.planFeatures}>
                ✓ Served adverts <br />
                ✓ No direct messaging to other clubs <br />
                ✓ Cannot see list of all games
              </Typography>
              <Button sx={styles.button}>Get Started</Button>
            </Paper>
          </Grid>

          {/* Pro Plan Monthly */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper sx={{ ...styles.card, ...styles.proCardMonthly }}>
              <Typography variant="h5" sx={styles.planTitle}>Pro Plan / Monthly</Typography>
              <Typography variant="h3" sx={styles.price}>£6.67/month</Typography>
              <Typography sx={styles.planFeatures}>
                ✓ Unlimited access to all features <br />
                ✓ Priority support <br />
                ✓ Ad-free experience
              </Typography>
              <Typography variant="h6" sx={styles.planTitle}>Save 20%</Typography>
              <Button sx={styles.button}>Subscribe</Button>
            </Paper>
          </Grid>

          {/* Pro Plan */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper sx={{ ...styles.card, ...styles.proCard }}>
              <Typography variant="h5" sx={styles.planTitle}>Pro Plan</Typography>
              <Typography variant="h3" sx={styles.price}>£100/year</Typography>
              <Typography sx={styles.planFeatures}>
                ✓ Unlimited access to all features <br />
                ✓ Priority support <br />
                ✓ Ad-free experience
              </Typography>
              <Button sx={styles.button}>Subscribe</Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
    </>
  );
};

const styles = {
  container: {
    minHeight: "100vh",
    backgroundColor: "#000",
    color: "#fff",
    textAlign: "center",
    paddingTop: "50px",
  },
  title: {
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subtitle: {
    color: "#bbb",
    marginBottom: "40px",
  },
  grid: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    padding: "30px",
    borderRadius: "12px",
    background: "linear-gradient(135deg, #ddccc6, #c9b1a9)",
    color: "#fff",
    textAlign: "center",
    boxShadow: "0px 4px 15px #ffffff",
  },
  proCard: {
    background: "linear-gradient(135deg, #9ab5ff, #c4d3fa)",
    color: "#000",
    boxShadow: "0px 8px 10px #8af6c7",
  },
  proCardMonthly: {
    background: "linear-gradient(135deg, #8af6c7, #72f1cd)",
    color: "#000",
    boxShadow: "0px 8px 10px #9ab5ff",
  },
  planTitle: {
    fontWeight: "bold",
    marginBottom: "10px",
  },
  price: {
    fontWeight: "bold",
    marginBottom: "15px",
  },
  planFeatures: {
    fontSize: "16px",
    lineHeight: "1.6",
    marginBottom: "20px",
  },
  button: {
    backgroundColor: "#fff",
    color: "#000",
    padding: "10px 20px",
    borderRadius: "25px",
    fontWeight: "bold",
    transition: "background-color 0.3s ease",
    '&:hover': {
      backgroundColor: "#bbb",
    },
  },
};

export default SubscriptionScreen;