import { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

// Custom hook to fetch and return club image
const useClubImage = (userId) => {
  const [clubPhotoBase64, setClubPhotoBase64] = useState("");
  const [clubName, setClubName] = useState("");

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!userId) return;
      try {
        const { data, error } = await supabase
          .from("userProfile")
          .select("club_photo, club_name")
          .eq("club_uuid", userId);

        if (error) throw error;
        if (data.length > 0) {
          const userProfile = data[0];
          setClubName(userProfile.club_name);
          const clubPhotoBase64 = userProfile.club_photo;
          setClubPhotoBase64(clubPhotoBase64);
          console.log("Club photo base64:", clubPhotoBase64);
        }
      } catch (error) {
        console.error("Error fetching club image:", error);
      }
    };
    fetchUserInfo();
  }, [userId]);

  return { clubPhotoBase64, clubName };
};

export default useClubImage;
