import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  List,
  ListItem,
} from "@mui/material";
import { supabase } from "../Components/supabaseClient";
import Calendar from "react-calendar";
import { useDispatch } from "react-redux";
import "../App.css";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import useClubImage from "../Components/Hooks/userImageHook";
import { Colors } from "../Components/Theme/Colors";

const Dashboard = () => {
  const [userId, setUserId] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [fixtures, setFixtures] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clubPhotoBase64, clubName } = useClubImage(userId);

  useEffect(() => {
    const getUser = async () => {
      try {
        const { data, error } = await supabase.auth.getUser();
        if (error) throw error;
        setUserId(data.user.id);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    getUser();
  }, []);

  const getAllFixtures = useCallback(async (date) => {
    setSelectedDate(date);
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

    try {
      const { data, error } = await supabase
        .from("fixture")
        .select("*")
        .eq("fixture_date", formattedDate);
      if (error) throw error;
      setFixtures(data);
    } catch (error) {
      console.error("Error fetching fixtures:", error);
    }
  }, []);

  useEffect(() => {
    getAllFixtures(selectedDate);
  }, [selectedDate, getAllFixtures]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const sendClubName = (clubName) => {
    navigate("/clubprofile", {
      state: {
        clubName: clubName,
      },
    });
  };

  const addFixture = () => {
    navigate("/addfixture");
  };

  return (
    <>
      <Header />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        minHeight="100vh"
        style={{
          background: Colors.background,
          padding: "20px",
        }}
      >
        {/* Club Image */}
        {clubPhotoBase64 ? (
          <Box display="flex" justifyContent="center">
            <img
              src={clubPhotoBase64}
              alt="Club"
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                objectFit: "cover",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="120px"
            height="120px"
            borderRadius="50%"
            backgroundColor={Colors.purple}
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
          >
            <Typography variant="h3" style={{ color: "white" }}>
              {clubName.charAt(0)}
            </Typography>
          </Box>
        )}

        {/* Club Name */}
        <Typography
          variant="h4"
          align="center"
          fontWeight="bold"
          marginTop={2}
          color="white"
          onClick={() => sendClubName(clubName)}
          sx={{ cursor: "pointer", textDecoration: "underline" }}
        >
          {clubName}
        </Typography>

        {/* Calendar */}
        <Box display="flex" justifyContent="center" marginY={3} backgroundColor={Colors.white} borderRadius="12px">
        <Calendar
          className="custom-calendar"
          value={selectedDate}
          onChange={handleDateChange}
          tileClassName={({ date }) =>
            date.toDateString() === selectedDate.toDateString() ? "selected-date" : ""
          }
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "12px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
              padding: "10px",
              border: `1px solid ${Colors.black10}`,
            }}
          />
        </Box>


        {/* Fixture List */}
        <List sx={{ width: "100%", maxWidth: "600px" }}>
          {fixtures.map((fixture, index) => (
            <ListItem key={index} sx={{ justifyContent: "center", marginBottom: "10px" }}>
              <Card
                sx={{
                  width: "100%",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
                  borderRadius: "12px",
                  backgroundColor: Colors.teal50,
                  padding: "10px",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    color={Colors.pinkDark}
                    fontWeight="bold"
                    sx={{ cursor: "pointer" }}
                    onClick={() => sendClubName(fixture.club_name)}
                  >
                    {fixture.club_name}
                  </Typography>
                  <Typography>
                    <strong>Fixture Date:</strong> {fixture.fixture_date}
                  </Typography>
                  <Typography>
                    <strong>Team Name:</strong> {fixture.fixture_team_name}
                  </Typography>
                  <Typography>
                    <strong>Venue:</strong> {fixture.fixture_venue || "Not specified"}
                  </Typography>
                  <Typography>
                    <strong>Distance:</strong>{" "}
                    {fixture.max_travel_radius ? `${fixture.max_travel_radius}km` : "Not specified"}
                  </Typography>
                  {fixture.fixture_status && (
                    <Typography color="red">
                      <strong>Status:</strong> {fixture.fixture_status}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>

        {/* Add Fixture Button */}
        <Button
          variant="contained"
          onClick={addFixture}
          sx={{
            marginTop: "20px",
            padding: "12px 20px",
            fontSize: "16px",
            fontWeight: "bold",
            borderRadius: "25px",
            backgroundColor: Colors.purple,
            color: "white",
            "&:hover": { backgroundColor: Colors.violet },
            boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
          }}
        >
          Add Fixture
        </Button>
      </Box>
    </>
  );
};

export default Dashboard;
