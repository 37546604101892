import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import subscribeToMessages from "./subscribeToMessages";

const useSendMessage = (existingConversationId, signedInUserClubName, receiverClubName) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (existingConversationId) {
      const unsubscribe = subscribeToMessages(existingConversationId, setMessages);
      return unsubscribe;
    }
  }, [existingConversationId]);

  const sendMessage = async () => {
    try {
      const { error: messageError } = await supabase.from("messages").insert([
        {
          conversation_id: existingConversationId,
          sender_id: signedInUserClubName,
          message_content: message,
        },
      ]);
      if (messageError) throw messageError;
      
      setMessage("");
      await sendNotification(receiverClubName, message);
    } catch (error) {
      console.error("Error sending message:", error.message);
    }
  };

  const sendNotification = async (receiver, messageContent) => {
    try {
      // Retrieve receiver's push token and club uuid
      const { data, error } = await supabase
        .from("userProfile")
        .select("app_id_for_signal, club_uuid")
        .eq("club_name", receiver)
        .single();
        
      if (error || !data?.app_id_for_signal) {
        console.error("Error fetching receiver's signal data:", error);
        return;
      }
      
      const signalAppId = data.app_id_for_signal;
      
      // Insert notification record into Supabase
      const { error: notifyError } = await supabase
        .from("notifications")
        .insert([
          {
            club_uuid: data.club_uuid,
            notification: messageContent,
            sender: signedInUserClubName,
            which_notification: "send_a_message",
            chat_id: existingConversationId,
          },
        ]);
      if (notifyError) {
        console.error("Error inserting notification:", notifyError);
      }
      
      // Prepare and send push notification
      const notificationPayload = {
        appId: signalAppId,
        sound: "default",
        title: `New Message from ${signedInUserClubName}`,
        body: messageContent,
        data: { conversationId: existingConversationId },
      };
      
      const response = await fetch(
        "https://bxzuxbvzbgkrnunmflgc.supabase.co/functions/v1/sendPushNotification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${process.env.REACT_APP_SUPABASE_ANON_KEY}`,
          },
        body: JSON.stringify(notificationPayload),
      });
      
      const responseData = await response.json();
      if (responseData.errors) {
        console.error("Error sending push notification:", responseData.errors);
      }
    } catch (error) {
      console.error("Error in sendNotification:", error);
    }
  };

  return {
    message,
    setMessage,
    messages,
    sendMessage,
    sendNotification,
  };
};

export default useSendMessage;