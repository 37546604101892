import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { supabase } from "../Components/supabaseClient";
import { postcodeValidator } from "postcode-validator";
import { Colors } from "../Components/Theme/Colors"; // Import FixtureFix color styles
import { Box, Typography, TextField, Button, Container, Card, CardContent } from "@mui/material";
import Header from "./Header";

const LoginAfterSigningUp = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [clubName, setClubName] = useState("");
  const [description, setDescription] = useState("");
  const [postcode, setPostcode] = useState("");
  const [whatThreeWords, setWhatThreeWords] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+44 ");
  const [imageCode, setImageCode] = useState("");
  const [imageUrl, setImageUrl] = useState(null);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    setIsLoading(true);
    try {
      const randomImageCode = Math.random().toString(36).substring(7);
      setImageCode(randomImageCode);
      
      const { data, error } = await supabase.storage
        .from("user_profile_avatars")
        .upload(`${randomImageCode}.png`, file);
  
      if (error) {
        console.log("Upload error:", error);
        return;
      }
      
      const { data: urlData } = supabase.storage
        .from("user_profile_avatars")
        .getPublicUrl(`${randomImageCode}.png`);

      setImageUrl(urlData.publicUrl);
    } catch (error) {
      console.error("Image upload failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProfileSubmit = async () => {
    if (!username || !clubName || !description || !postcode || !phoneNumber) {
      alert("All required fields must be filled.");
      return;
    }
    if (!postcodeValidator(postcode, "GB")) {
      alert("Please enter a valid UK postcode.");
      return;
    }
  
    setIsLoading(true);
    try {
      const email = location.state?.email;
      const password = location.state?.password;
      if (!email || !password) {
        alert("Email or password not found");
        return;
      }
  
      const authResponse = await supabase.auth.signInWithPassword({ email, password });
      if (authResponse.error) {
        alert("Authentication Error: " + authResponse.error.message);
        return;
      }
  
      const userId = authResponse.data.user.id;
      const userProfileData = {
        club_location: postcode,
        club_name: clubName,
        contact_number: phoneNumber,
        club_description: description,
        club_email: email,
        what_three_words: whatThreeWords,
        profile_setup_complete: true,
        club_photo: imageUrl,
        club_photo_url: imageUrl,
        club_uuid: userId,
        user_profile_icon_code: imageCode,
      };
  
      const { error } = await supabase.from("userProfile").upsert([userProfileData]);
      if (error) throw error;
      window.location.href = "/dashboard";
    } catch (error) {
      alert("Error: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        style={{
          background: `linear-gradient(135deg, ${Colors.background}, ${Colors.blue80})`, // Matching other forms
        }}
      >
        <Container maxWidth="xs">
          <Card
            style={{
              padding: "2rem",
              boxShadow: "0px 4px 12px rgba(0,0,0,0.15)",
              backgroundColor: Colors.teal50, // Same as Sign Up & Login
              borderRadius: "12px",
            }}
          >
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{ fontWeight: "bold", color: Colors.pinkDark }}
                >
                  User Profile
                </Typography>
                <TextField
                  label="Username"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      borderColor: Colors.pinkDark,
                    },
                  }}
                />
                <TextField
                  label="Club Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={clubName}
                  onChange={(e) => setClubName(e.target.value)}
                />
                <TextField
                  label="Club Description"
                  variant="outlined"
                  multiline
                  fullWidth
                  margin="normal"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  sx={{ minHeight: "80px" }}
                />
                <TextField
                  label="Postcode"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={postcode}
                  onChange={(e) => setPostcode(e.target.value)}
                />
                <TextField
                  label="What Three Words"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={whatThreeWords}
                  onChange={(e) => setWhatThreeWords(e.target.value)}
                />
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <Button
                  variant="contained"
                  component="label"
                  fullWidth
                  style={{
                    marginTop: "1rem",
                    borderRadius: "8px",
                    fontWeight: "bold",
                    backgroundColor: Colors.pink,
                    color: "#FFFFFF",
                  }}
                >
                  Upload Image
                  <input type="file" hidden accept="image/*" onChange={handleImageUpload} disabled={isLoading} />
                </Button>
                {imageUrl && (
                  <Box mt={2} textAlign="center">
                    <img src={imageUrl} alt="Profile Avatar" style={{ maxWidth: "100%", borderRadius: "8px" }} />
                  </Box>
                )}
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    marginTop: "1rem",
                    borderRadius: "8px",
                    padding: "0.75rem",
                    fontWeight: "bold",
                    backgroundColor: "#6558f5",
                    color: "#FFFFFF",
                    boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
                  }}
                  onClick={handleProfileSubmit}
                  disabled={isLoading}
                >
                  Submit
                </Button>
              </Box>
            </CardContent>
          </Card>
          <Typography
            variant="body2"
            style={{
              marginTop: "2rem",
              textAlign: "center",
              color: "#FFFFFF",
            }}
          >
            © 2025 FixtureFix. All Rights Reserved.
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default LoginAfterSigningUp;
