import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { supabase } from "../Components/supabaseClient";
import "../Components/Styles/ClubProfile.css";
import Header from "./Header";

const ClubProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [fixtures, setFixtures] = useState([]);
  const [clubInfo, setClubInfo] = useState({
    clubName: "",
    clubLocation: "",
    clubContact: "",
    clubEmail: "",
    clubWebsite: "",
    clubDescription: "",
  });

  const getClubInfo = async (clubName) => {
    try {
      const response = await fetchClubDataFromSupabase(clubName);
      setClubInfo({
        clubName: response.club_name,
        clubLocation: response.club_location,
        clubContact: response.contact_number,
        clubEmail: response.club_email,
        clubWebsite: response.website,
        clubDescription: response.description,
      });

      const { data, error } = await supabase
        .from("fixture")
        .select("*")
        .eq("club_name", clubName);

      if (error) throw error;
      setFixtures(data);
    } catch (error) {
      console.error("Error fetching club details or fixtures:", error);
    }
  };

  const fetchClubDataFromSupabase = async (clubName) => {
    const { data, error } = await supabase
      .from("userProfile")
      .select("*")
      .eq("club_name", clubName)
      .single();

    if (error) throw error;
    return data;
  };

  useEffect(() => {
    if (location.state && location.state.clubName) {
      getClubInfo(location.state.clubName);
    }
    // eslint-disable-next-line
  }, [location]);

  const goToMapScreen = () => {
    navigate("/clublocation", { state: { clubName: clubInfo.clubName } });
  };

  return (
    <>
      <Header />
      <div className="club-profile-container">
        <div className="club-info-row">
          <div className="club-info-item">
            <h2>Club Name</h2>
            <p>{clubInfo.clubName}</p>
          </div>
          <div className="club-info-item">
            <h2>Club Location</h2>
            <p>{clubInfo.clubLocation}</p>
            <button onClick={goToMapScreen}>View on a Map</button>
          </div>
        </div>

        <div className="club-info-row">
          <div className="club-info-item">
            <h2>Club Contact</h2>
            <p>
              <a href={`tel:${clubInfo.clubContact}`}>{clubInfo.clubContact}</a>
            </p>
          </div>
          <div className="club-info-item">
            <h2>Club Email</h2>
            <p>
              <a href={`mailto:${clubInfo.clubEmail}`}>{clubInfo.clubEmail}</a>
            </p>
          </div>
        </div>
        <div className="fixtures">
          <h2>Fixtures</h2>
          <ul>
            {fixtures.map((fixture) => (
              <li key={fixture.id} className="fixture-item">
                <p className="fixture-club-name">{fixture.club_name}</p>
                <p className="fixture-date">{fixture.fixture_date}</p>
                <p className="fixture-venue">{fixture.fixture_venue}</p>
                <p className="fixture-description">{fixture.fixture_description}</p>
              </li>
            ))}
          </ul>
        </div>
        </div>

    </>
  );
};

export default ClubProfile;