import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, TextField, Button, Container, Card, CardContent } from "@mui/material";
import Header from "./Header";
import { Colors } from "../Components/Theme/Colors";

const OtpVerify = () => {
  const [otp, setOtp] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const verifyOtp = () => {
    const { randomCode } = location.state || {};
    otp === randomCode?.toString() ? signUserUp() : alert("Incorrect OTP. Please try again.");
  };

  const signUserUp = () => {
    navigate("/loginaftersigningup", {
      state: {
        email: location.state?.email,
        password: location.state?.password,
      },
    });
  };

  return (
    <>
      <Header />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        style={{
          background: "linear-gradient(135deg, #19262d, #1e3a8a)", // Matching Sign Up & Login
        }}
      >
        <Container maxWidth="xs">
          <Card
            style={{
              padding: "2rem",
              boxShadow: "0px 4px 12px rgba(0,0,0,0.15)",
              backgroundColor: Colors.teal50, // Same as Sign Up & Login
              borderRadius: "12px",
            }}
          >
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{ fontWeight: "bold", color: Colors.pinkDark }}
                >
                  OTP Verification
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  align="center"
                  gutterBottom
                  style={{ color: Colors.black80 }}
                >
                  Please enter the OTP sent to your registered email.
                </Typography>
                <TextField
                  label="Enter OTP"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  sx={{
                    maxWidth: "300px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      borderColor: Colors.pinkDark,
                    },
                  }}
                />
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    marginTop: "1rem",
                    borderRadius: "25px",
                    padding: "0.75rem",
                    fontWeight: "bold",
                    backgroundColor: "#6558f5",
                    color: "#FFFFFF",
                    boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
                    transition: "0.3s",
                  }}
                  onMouseOver={(e) => (e.target.style.backgroundColor = "#5648e0")}
                  onMouseOut={(e) => (e.target.style.backgroundColor = "#6558f5")}
                  onClick={verifyOtp}
                >
                  Verify OTP
                </Button>
              </Box>
            </CardContent>
          </Card>
          <Typography
            variant="body2"
            style={{
              marginTop: "2rem",
              textAlign: "center",
              color: "#FFFFFF",
            }}
          >
            © 2025 FixtureFix. All Rights Reserved.
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default OtpVerify;
