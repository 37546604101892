import React from "react";
import { supabase } from "../Components/supabaseClient";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Link,
  Card,
  CardContent,
} from "@mui/material";
import Header from './Header';
import { Colors } from "../Components/Theme/Colors";

const SignUp = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const navigate = useNavigate();

  const signUserUp = async (randomCode) => {
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    const { error } = await supabase.auth.signUp({
      email: email,
      password: password,
    });
    if (error) {
      alert("Error signing up: " + error.message);
    } else {
      navigate("/otpverify", {
        state: { randomCode: randomCode, email: email, password: password },
      });
    }
  };

  const callEmailVerifyFunction = async () => {
    const randomCode = Math.floor(1000 + Math.random() * 9000);
    try {
      const res = await fetch(
        "https://bxzuxbvzbgkrnunmflgc.supabase.co/functions/v1/proxy-function",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from: "FixtureFix <onboarding@fixturefix.co.uk>",
            to: [email],
            subject: "FixtureFix Email Verification",
            html: `<p>Enter the code into the verification screen and your email will be validated</strong><strong> <br/>${randomCode}</strong>`,
          }),
        }
      );
      const data = await res.json();
      res.status === 200 ? signUserUp(randomCode) : alert("Error: " + data.message);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <>
      <Header />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        style={{
          background: "linear-gradient(135deg, #19262d, #1e3a8a)",
        }}
      >
        <Container maxWidth="xs">
        <Card
            style={{
              padding: "2rem",
              boxShadow: "0px 4px 12px rgba(0,0,0,0.15)",
              backgroundColor: Colors.teal50, 
              borderRadius: "12px",
            }}
          >
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{ fontWeight: "bold", color: Colors.pinkDark }} // Softer black
                >
                  Sign Up
                </Typography>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    style: { borderColor: Colors.pinkDark, borderRadius: "8px" }, // Subtle border
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    style: { borderColor: "#D1D1D1", borderRadius: "8px" },
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                  error={password !== confirmPassword && confirmPassword.length > 0}
                  helperText={
                    password !== confirmPassword && confirmPassword.length > 0
                      ? "Passwords do not match"
                      : ""
                  }
                  label="Confirm Password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  margin="normal"
                  InputProps={{
                    style: { borderColor: "#D1D1D1", borderRadius: "8px" },
                  }}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    marginTop: "1rem",
                    borderRadius: "25px",
                    padding: "0.75rem",
                    fontWeight: "bold",
                    backgroundColor: "#6558f5",
                    color: "#FFFFFF",
                    boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
                    transition: "0.3s",
                  }}
                  onMouseOver={(e) => (e.target.style.backgroundColor = "#5648e0")}
                  onMouseOut={(e) => (e.target.style.backgroundColor = "#6558f5")}
                  onClick={callEmailVerifyFunction}
                >
                  Sign Up
                </Button>
                <Typography
                  variant="body2"
                  style={{ marginTop: "1rem", color:"#FFFFFF", }}
                >
                  Already have an account?{" "}
                  <Link href="/login" underline="hover" style={{ color:Colors.pink, fontWeight: "bold" }}>
                    Login
                  </Link>
                </Typography>
              </Box>
            </CardContent>
          </Card>
          <Typography
            variant="body2"
            style={{
              marginTop: "2rem",
              textAlign: "center",
              color:"#FFFFFF",
            }}
          >
            © 2025 FixtureFix. All Rights Reserved.
          </Typography>
        </Container>
      </Box>

    </>
  );
};

export default SignUp;
